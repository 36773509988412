import React from 'react'
import { Link } from 'react-router-dom'
import { Banner, Breadcrumb, Title } from 'components'

export default function IndiceDocumenti() {
  return (
    <>
      <Banner />
      <Title title="Materiale informativo scientifico" />
      <Breadcrumb current="Materiale informativo scientifico" />
      <br />
      <Link
        to={'documenti?product=ACRONELLE'}
        style={{ textDecoration: 'none', display: 'flex' }}
      >
        <img src="acronelle.png" alt="Acronelle" width={150} height={50} />
      </Link>

      <Link
        to={'documenti?product=ADOMELLE'}
        style={{ textDecoration: 'none', display: 'flex' }}
      >
        <img src="adomelle.png" alt="Adomelle" width={150} height={50} />
      </Link>

      <Link
        to={'documenti?product=BIFISELLE'}
        style={{ textDecoration: 'none', display: 'flex' }}
      >
        <img src="bifiselle.png" alt="Bifiselle" width={150} height={50} />
      </Link>

      <Link
        to={'documenti?product=CITOGENEX'}
        style={{ textDecoration: 'none', display: 'flex' }}
      >
        <img src="citogenex.png" alt="Citogenex" width={150} height={50} />
      </Link>

      <Link to={null} style={{ textDecoration: 'none', display: 'flex' }}>
        <img
          src="crispagyn.png"
          alt="Crispagyn (pagina in aggiornamento)"
          width={131}
          height={50}
        />
        <span style={{ fontSize: '25px' }}>*</span>
      </Link>

      <Link
        to={'documenti?product=ENTERELLE'}
        style={{ textDecoration: 'none', display: 'flex' }}
      >
        <img src="enterelle.png" alt="Enterelle" width={150} height={50} />
      </Link>

      <Link to={null} style={{ textDecoration: 'none', display: 'flex' }}>
        <img
          src="femelle.png"
          alt="Femelle (pagina in aggiornamento)"
          width={98}
          height={50}
        />
        <span style={{ fontSize: '25px' }}>*</span>
      </Link>

      <Link
        to={'documenti?product=PILOREX'}
        style={{ textDecoration: 'none', display: 'flex' }}
      >
        <img src="pilorex.png" alt="Pilorex" width={150} height={50} />
      </Link>

      <Link
        to={'documenti?product=RAMNOSELLE'}
        style={{ textDecoration: 'none', display: 'flex' }}
      >
        <img src="ramnoselle.png" alt="Ramnoselle" width={150} height={50} />
      </Link>

      <Link
        to={'documenti?product=SEROBIOMA'}
        style={{ textDecoration: 'none', display: 'flex' }}
      >
        <img src="serobioma.png" alt="Serobioma" width={150} height={50} />
      </Link>
      <br />
      <br />
      <br />
      <div style={{ fontSize: '14px', fontStyle: 'italic' }}>
        * Pagina in aggiornamento
      </div>
    </>
  )
}
