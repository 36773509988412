import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
// import { SizeMe } from 'react-sizeme'
import { useParams } from 'react-router-dom'
import PDFViewer from 'pdf-viewer-reactjs'
import { GET_DOCUMENT } from 'query'
import { useQuery } from '@apollo/client'
import { getDocument, getDocumentVariables } from 'types/generated/schemaTypes'
import { Breadcrumb, Title } from 'components'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .pdf-viewer-navbar-wrapper': {
      marginTop: '0px',
      color: 'white',
      backgroundColor: 'black',
      borderRadius: '6px',
      boxShadow:
        '0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02)',
      padding: '1.25rem',
    },
  },
}))

export default function Documento() {
  const classes = useStyles()
  const { id } = useParams<{ id: string }>()
  const {
    data: documentData,
    loading: documentLoading,
    error: documentError,
  } = useQuery<getDocument, getDocumentVariables>(GET_DOCUMENT, {
    fetchPolicy: 'network-only',
    variables: {
      id,
    },
  })

  return (
    <>
      <Title title="Materiale informativo scientifico" />
      <Breadcrumb current="Materiale informativo scientifico" />
      <div>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} className={classes.root}>
            {documentLoading && <div>Caricamento del documento in corso</div>}
            {!documentLoading && !documentError && documentData && (
              <PDFViewer
                withCredentials={process.env.NODE_ENV === 'production'}
                scaleStep={0.5}
                scale={1.5}
                document={{
                  url: documentData.getDocument?.url,
                }}
                navbarOnTop={false}
                css="pdf-viewer-wrapper"
                // canvasCss="pdf-viewer"
                navigation={{
                  css: {
                    navbarWrapper: 'pdf-viewer-navbar-wrapper',
                  },
                }}
              />
            )}
          </Grid>
        </Grid>
      </div>
    </>
  )
}
